import { ModalTypes } from '@dtx-company/inter-app/src/redux/slices/modal'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { dismissTrialIntro, hasDismissedTrialIntro } from '../utils/dismissedTrialIntro'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useEffect } from 'react'
import { useFlowRouter } from '@dtx-company/inter-app/src/hooks/useFlowRouter'
import { useModal } from '@dtx-company/inter-app/src/redux/slices/utils'
import { useTrialConfig } from '@app/common/src/hooks/useTrialConfig/useTrialConfig'

export interface TrialIntroOverlayStateProps {
  modalOpen: boolean
  setClose(): void
}

export const useTrialIntroOverlayState = (): TrialIntroOverlayStateProps => {
  const { modalOpen, setOpen, setClose: closeModal } = useModal(ModalTypes.TRIAL_INTRO_OVERLAY)
  const { isAuthChecked, isAuthenticated } = useAuthState()
  const router = useFlowRouter()
  const trialConfig = useTrialConfig()
  const dismissedTrialIntro = hasDismissedTrialIntro()
  const routeBlockList = [
    Routes.SIGN_IN,
    Routes.SIGN_UP,
    Routes.FREE_QR_CODE_GENERATOR,
    Routes.BUY_PLAN_PAGE,
    Routes.ACCEPT_INVITE,
    Routes.ACCEPT_ORG_INVITE,
    Routes.VALIDATE_PRIVACY_REQUEST,
    Routes.NOT_FOUND
  ]
  const routeIsBlocked = routeBlockList.some(route => router.pathname === route)

  const showTrialIntroOverlay =
    trialConfig?.inTrial &&
    !dismissedTrialIntro &&
    isAuthChecked &&
    isAuthenticated &&
    !routeIsBlocked

  const setClose = (): void => {
    closeModal()
    dismissTrialIntro()
  }

  useEffect(() => {
    if (router.pathname === Routes.PAGE_EDITOR && window.location.hostname.startsWith('www')) return
    if (showTrialIntroOverlay) {
      setOpen()
    }
  }, [isAuthChecked, isAuthenticated, router.pathname, setOpen, showTrialIntroOverlay])
  return { modalOpen, setClose }
}
